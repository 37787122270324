/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import React, { ReactElement, ReactNode } from "react";
import Head from "next/head";
import FavIcons from "@components/shared/favIcon";
import ClickyScript from "@components/scripts/clickyScript";
import InspectorScript from "@components/scripts/inspectorScript";
import GtmScript from "@components/scripts/gtmScript";
import LeadId from "@components/scripts/leadid";
import { useFormContext } from "@hooks/useFormContext";
import { DomainInfo } from "src/api";
// import TrustedFormScript from "@components/scripts/trustedFormScript";
import MinFraudScript from "@components/scripts/minFraudScript";
import TwitterTagScript from "@components/scripts/twitterTagScript";
import { useDomainContext } from "@hooks/useDomainContext";
import MetaPixelScript from "@components/scripts/metaPixel";
import TickTokPixelScript from "@components/scripts/tiktokPixel";
import Script from "next/script";
import useOneSignal from "@hooks/useOneSignal";

import { Category } from "@lib/shared/types";
export default function DomainWrapper({
    domain,
    pageTitle,
    children,
}: {
    domain: DomainInfo | null;
    pageTitle: string | null;
    children: ReactNode;
}): ReactElement {
    const {
        // analyticsCodeId: matomoId = null,
        clickyCodeId: clickyId = null,
        metatags = null,
        gtmId = null,
        isInspectorEnabled = null,
        description = null,
        enableTrustedForm = null,
        enableJornayaId = null,
        twitterPixel = null,
    } = domain || {};

    const { formSubmitted, isSecondServiceSubmitted } = useFormContext();
    const {
        isBot,
        testConfig,
        visitDetails,
        externalFavicon,
        category,
        loadOneToOneForm,
    } = useDomainContext();
    const titleText = `${pageTitle ?? ""} ${pageTitle ? "|" : ""} ${
        domain?.niceName ? domain.niceName : domain?.title ?? ""
    }`;

    const isSeveralBrands = domain?.name === "severalbrands.com";
    const secondServiceList = domain?.categories
        ?.find(
            (domainCategory: Category) =>
                domainCategory.slug === category?.slug,
        )
        ?.secondServiceCategories?.map(
            (secondService) => secondService.secondServiceCategory,
        );
    const hasDomainSecondService =
        secondServiceList && secondServiceList?.length > 0;

    const stopInspector: boolean | undefined = hasDomainSecondService
        ? isSecondServiceSubmitted
        : formSubmitted;

    useOneSignal();

    const domainCss = domain?.config?.css;

    return (
        <>
            <Head>
                {!isBot && <MinFraudScript />}
                {<FavIcons externalFavicon={externalFavicon} />}
                {domainCss ? (
                    <>
                        <style
                            dangerouslySetInnerHTML={{
                                __html: domainCss,
                            }}
                        />
                    </>
                ) : null}
                {externalFavicon && (
                    <link
                        rel="icon"
                        type="image/x-icon"
                        href={externalFavicon}
                        className="favicon"
                    />
                )}
                {!isSeveralBrands && <title>{titleText}</title>}
                {enableJornayaId && !isBot ? <LeadId /> : null}
                {description && (
                    <meta name="description" content={description} />
                )}
                {metatags &&
                    metatags.length &&
                    metatags
                        .filter((meta) => !meta.key.startsWith("extra-meta"))
                        .map((item, index) => (
                            <meta
                                name={item.key}
                                content={item.value}
                                key={index}
                            />
                        ))}
                {twitterPixel && !isBot && (
                    <TwitterTagScript twitterPixel={twitterPixel} />
                )}
                {!isBot &&
                    domain?.advertisingNetworkTrackingSettings?.pixelId &&
                    domain?.advertisingNetworkTrackingSettings?.network ===
                        "facebook" && (
                        <MetaPixelScript
                            externalId={visitDetails?.visitId}
                            pixelId={
                                domain.advertisingNetworkTrackingSettings
                                    ?.pixelId
                            }
                        />
                    )}
                {!isBot &&
                    domain?.advertisingNetworkTrackingSettings?.pixelId &&
                    domain?.advertisingNetworkTrackingSettings?.network ===
                        "tiktok" && (
                        <TickTokPixelScript
                            hashedExternalId={
                                visitDetails?.hashedVisitId as string
                            }
                            pixelId={
                                domain.advertisingNetworkTrackingSettings
                                    ?.pixelId
                            }
                        />
                    )}
            </Head>
            {gtmId && !isBot && <GtmScript gtmId={gtmId} />}
            {enableTrustedForm && !isBot ? (
                <>
                    <Script
                        id="trustedFormsScript"
                        strategy="lazyOnload"
                        src={`https://api.trustedform.com/trustedform.js?${
                            loadOneToOneForm ? "use_tagged_consent=true&" : ""
                        }field=TrustedFormCertUrl&provide_referrer=true&load_async=true&l="${
                            new Date().getTime() + Math.random()
                        }"`}
                    />
                    <noscript>
                        <img
                            src="http://api.trustedform.com/ns.gif"
                            alt="trusted form"
                        />
                    </noscript>
                </>
            ) : null}

            {testConfig?.config?.css && !testConfig?.config?.isDefault ? (
                <>
                    <style
                        dangerouslySetInnerHTML={{
                            __html: testConfig.config.css,
                        }}
                    />
                </>
            ) : null}

            {clickyId && !isBot && <ClickyScript id={clickyId} />}
            <Script
                id="changeFavicon"
                strategy="beforeInteractive"
                dangerouslySetInnerHTML={{
                    __html: `let e=document.getElementsByClassName("favicon"),t=e[0].href;const n=new URLSearchParams(window.location.search),a=Object.fromEntries(n.entries());function c(t){e&&e?.length&&t&&[...e].forEach((e=>{e.href=t}))}window.setInterval((()=>{"hidden"===document.visibilityState&&a&&a?.ts&&a?.ts?.includes("pm")?e&&[...e].forEach((e=>{e.href===t?c("https://dwy9ix7d387oz.cloudfront.net/attachments/2024/1/20da118f-a119-492e-bf66-9592ac880743.png"):c(t)})):c(t)}),1e3);`,
                }}
            />

            {isInspectorEnabled && !isBot && (
                <InspectorScript stopInterval={stopInspector} />
            )}

            {children}
        </>
    );
}
